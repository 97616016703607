@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

$imageSizes-height-xs: 370px;
$imageSizes-height-md: 450px;
$imageSizes-height-lg: 550px;

$imageSizes-width-xs: 240px;
$imageSizes-width-md: 288px;
$imageSizes-width-lg: 352px;

.root {
  padding-bottom: 1rem;
  position: relative;
  overflow: hidden;
  width: $imageSizes-width-xs;
  margin: 0 auto;

  @include devices(md-up) {
    margin: spacing(4) auto;
    width: $imageSizes-width-md;
  }

  @include devices(lg-up) {
    width: $imageSizes-width-lg;
  }
}

.image {
  height: $imageSizes-height-xs;
  margin: spacing(0, 0, 3, 0);
  width: 100%;
  border-radius: 4px;

  @include devices(md-up) {
    margin: 0 auto;
    height: $imageSizes-height-md;
  }

  @include devices(lg-up) {
    height: $imageSizes-height-lg;
  }
}

.headlineWrapper {
  position: absolute;
  overflow: hidden;
  left: 0;
  right: 0;
  bottom: 4px;
  padding: 0.5rem;
  height: 38%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  margin: 0 0.5rem;
  background-color: var(--background-default);

  @include devices(md-up) {
    height: 35%;
    margin: 0 1rem;
    padding: 1rem;
  }
}

.headline {
  font-size: 0.875rem;
  font-weight: normal;

  @include devices(lg-up) {
    font-size: 1rem;
  }
}
